<template>
    <el-tooltip placement="top">
        <el-button class="round-button" icon="el-icon-info" circle />
        <div slot="content" class="tooltip-content">
            <p v-html="content" />
        </div>
    </el-tooltip>
</template>

<script>
export default {
    props: {
        content: {
            type: String,
        },
    },
};
</script>
